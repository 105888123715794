<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button v-if="$can('slideshow-add')" label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" @click="openNew" />
						</template>
					</Toolbar>

					<DataView :value="dataView.data" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						:rowsPerPageOptions="dataView.rows_option"
						:layout="dataView.layout" 
						:paginator="dataView.paginator" 
						:rows="dataView.params.rows" 
						:totalRecords="dataView.totalRecords" 
						:lazy="true" 
						@page="loadData($event)">
						<template #header>
							<div class="grid p-nogutter">
								<div class="col-6" style="text-align: left"></div>
								<div class="col-6" style="text-align: right">
									<DataViewLayoutOptions v-model="dataView.layout" />
								</div>
							</div>
						</template>
						<template #list="slotProps" >
							<div class="col-12">
								<div class="product-list-item">
									<Image :src="loadImageSlide(slotProps.data.image)" :alt="slotProps.data.title" preview/>
									<div class="product-list-detail">
										<div class="product-name">{{slotProps.data.title}}</div>
										<!-- <div class="product-description">{{slotProps.data.description}}</div> -->
									</div>
									<div class="product-list-action">
										<span class="product-price"></span>
										<div>
											<Button v-if="$can('slideshow-edit')" @click="openEdit(slotProps.data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" />
											<Button v-if="$can('slideshow-delete')" @click="openDelete('single', slotProps.data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-danger" />
										</div>
										<Tag v-if="slotProps.data.status == 0" severity="danger" value="Tidak Aktif" class="mr-1" />
										<Tag v-else-if="slotProps.data.status == 1" severity="primary" value="Aktif" class="mr-1" />
									</div>
								</div>
							</div>
						</template>
						<template #grid="slotProps">
							<div class="col-12 md:col-4">
								<div class="product-grid-item card">
									<div class="product-grid-item-top">
										<div></div>
										<Tag v-if="slotProps.data.status == 0" severity="danger" value="Tidak Aktif" class="mr-1" />
										<Tag v-else-if="slotProps.data.status == 1" severity="primary" value="Aktif" class="mr-1" />
									</div>
									<div class="product-grid-item-content">
										<Image :src="loadImageSlide(slotProps.data.image)" :alt="slotProps.data.title" preview/>
										<div class="product-name">{{slotProps.data.title}}</div>
										<!-- <div class="product-description">{{slotProps.data.description}}</div> -->
									</div>
									<div class="product-grid-item-bottom">
										<span class="product-price"></span>
										<div>
											<Button v-if="$can('slideshow-edit')" @click="openEdit(slotProps.data)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" />
											<Button v-if="$can('slideshow-delete')" @click="openDelete('single', slotProps.data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-danger" />
										</div>
									</div>
								</div>
							</div>
						</template>
					</DataView>

					<!-- Form -->
					<Dialog v-model:visible="dialog.formData" :header="formDataTitle" :style="{width: '450px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="field">
							<label>Title</label>
							<InputText v-model="dataForm.title" autofocus
								:class="{ 'p-invalid': dataForm.errors.has('title') }" />
							<small class="p-error" v-show="dataForm.errors.has('title')">
								{{ dataForm.errors.get('title') }}
							</small>
						</div>

						<div class="field">
							<label>Image</label> (1000x600) (maxsize:10mb)
							<InputText @change="selectFile" type="file" accept="image/*"
								:class="{ 'p-invalid': dataForm.errors.has('image') }" />
							<small class="p-error" v-show="dataForm.errors.has('image')">
								{{ dataForm.errors.get('image') }}
							</small>
						</div>

						<div class="field">
							<label>Description</label>
							<Textarea v-model="dataForm.description"
								:class="{ 'p-invalid': dataForm.errors.has('description') }" />
							<small class="p-error" v-show="dataForm.errors.has('description')">
								{{ dataForm.errors.get('description') }}
							</small>
						</div>

                        <div class="field">
							<label>Status</label>
							<Dropdown v-model="dataForm.status" optionValue="id" optionLabel="name"
								:options="relations.status"
								:class="{ 'p-invalid': dataForm.errors.has('status') }" />
							<small class="p-error" v-show="dataForm.errors.has('status')">
								{{ dataForm.errors.get('status') }}
							</small>
						</div>

						<template #footer>
							<Button label="Save" @click="submitData" :disabled="dataForm.busy" :icon="(dataForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-text" style="float:right" />
							<Button label="Cancel" @click="dialog.formData = false" icon="pi pi-times" class="p-button-text" />
						</template>
					</Dialog>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Slideshow',
			api: '/api/slideshow',
			dataForm: new this.$FormAuth({
				mode: null,
				id: null,
				title: null,
				image: null,
                description: null,
                status: "1",
			}),
			relations: {
                status: null,
            },
			dataView: {
				data: [],
				paginator: true,
				layout: 'grid',
				totalRecords: 0,
				rows_option: [9, 30, 99],
				selectedData: [],
				params: {
					rows: 9, //per_page
					page: 0,
					// sortField: null,
					// sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			(event) ? this.dataView.params = event : null;
			this.$httpAuth.get(this.api, {
				params: {
					rows: this.dataView.params.rows,
					page: this.dataView.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataView.data = response.data.data;
				this.dataView.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataView.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		loadImageSlide(data){
			return (data) ? `${process.env.VUE_APP_ASSETS_FOLDER}/slideshow/${data}` : process.env.VUE_APP_DEFAULT_NO_IMAGE;
		},

		selectFile(e){
			const file = e.target.files[0];
			this.dataForm.image = file;
		},

        getStatus(){
            this.$httpAuth.get(this.api + '/get/status')
            .then((response) => {
                this.relations.status = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		openNew() {
			this.resetForm();
			this.dataForm.mode = 'add';
			this.formDataTitle = 'Tambah '+this.title;
			this.dialog.formData = true;
		},
		openEdit(data){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/' + data.id + '/edit')
			.then((response) => {
				this.$Progress.finish();
				this.resetForm();
				this.dataForm.fill(response.data);
				this.dataForm.mode = 'edit';
				this.formDataTitle = 'Edit '+this.title;
				this.dialog.formData = true;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataView.selectedData = [];
				this.dataView.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		resetForm(){
			this.dataForm.clear();
			this.dataForm.reset();
		},
		submitData(){
			this.$Progress.start();
			if(this.dataForm.mode == 'add'){
				this.dataForm.post(this.api)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			} else if(this.dataForm.mode == 'edit') {
				this.dataForm.post(this.api + '/' + this.dataForm.id)
				.then((response) => {
					this.$Progress.finish();
					this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
					this.dialog.formData = false;
					this.loadData();
				})
				.catch((error) => {
					this.$Progress.fail();
					this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
				});
			}
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataView.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
        this.getStatus();
	},
}
</script>

<style lang="scss" scoped>
.card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
}
// .p-dropdown {
//     width: 14rem;
//     font-weight: normal;
// }

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	img {
		width: 50px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid var(--surface-border);

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
		width: 100%;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>